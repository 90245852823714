import React from "react"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout"
import "../styles/legal.css"

const Legal = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const schema = {
    "@context": t("SEO.legal.schema.context"),
    "@type": t("SEO.legal.schema.type"),
    name: t("SEO.legal.schema.name"),
    description: t("SEO.legal.schema.description"),
    url: t("SEO.legal.schema.url"),
    image: t("SEO.legal.schema.image"),
    sameAs: t("SEO.legal.schema.sameAs"),
    jobTitle: t("SEO.legal.schema.jobTitle"),
  }

  const datos = {
    title: t("SEO.legal.datos.title"),
    description: t("SEO.legal.datos.description"),
    lang: language,
  }

  return (
    <Layout schema={schema} datos={datos}>
      <section className="container max-w-7xl mt-10 sm:mt-16 sm:px-10">
        <h2 className="border-t border-b text-center py-4 text-3xl sm:text-4xl uppercase">
          {t("avisolegal")}
        </h2>
        <article
          dangerouslySetInnerHTML={{ __html: t("legal") }}
          className="legal"
        ></article>
      </section>
    </Layout>
  )
}

export default Legal

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
